import { setToken, getToken, POST_OPTS } from './network'
import { PRIVATE_API_ENDPOINT as API_ENDPOINT } from '../../constants/url.js'

export const get = async () => {
    try {
        const response = await fetch(`${API_ENDPOINT}/education/getList`, POST_OPTS());
        return await response.json();
    } catch (error) {
        console.error(error)
    }
}
export const getByID = async (id) => {
    try {
        const response = await fetch(`${API_ENDPOINT}/education/getByID`, {
            body: JSON.stringify({ id }),
            ...POST_OPTS()
        });
        return await response.json();
    } catch (error) {
        console.error(error)
    }
}
export const create = async (education, uploadFiles) => {
    
    const formData = new FormData();
    formData.append('payload', JSON.stringify({ ...education }))

    if (uploadFiles) {
        for (let image in uploadFiles) {
            formData.append('gallery', uploadFiles[image])
        }
    }
    try {
        const response = await fetch(`${API_ENDPOINT}/education/create`, {
            body: formData,
            ...POST_OPTS('multipart/form-data')
        });
        return await response.json();
    } catch (error) {
        console.error(error)
    }
}

export const update = async (education, uploadFiles) => {

    
    const formData = new FormData();
    console.log("ID", education.id || education._id)
    formData.append('id', education.id || education._id)
    formData.append('payload', JSON.stringify({...education}))

    if (uploadFiles) {
        for (let image in uploadFiles) {
            formData.append('gallery', uploadFiles[image])
        }
    }
    try {
        const response = await fetch(`${API_ENDPOINT}/education/update`, {
            body: formData,
            ...POST_OPTS('multipart/form-data')
        });
        return await response.json();
    } catch (error) {
        console.error(error)
    }
}
export const remove = async (id) => {
    try {
        const response = await fetch(`${API_ENDPOINT}/education/remove`, {
            body: JSON.stringify({id}),
            ...POST_OPTS()
        });
        return await response.json();
    } catch (error) {
        console.error(error)
    }
}

export default {
    get,
    getByID,
    create,
    update,
    remove
}