import "./index.css";
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
  Route,
} from "react-router-dom";


import Login from './routes/Login'
import PrivateRoute from './routes/PrivateRoute'
import Home from './routes/Home'
import UserListRoute from './routes/UserListRoute'
import UserRoute from './routes/UserRoute'
import MagazineListRoute from './routes/MagazineListRoute'
import MagazineRoute from './routes/MagazineRoute'
import PreviewRoute from "./routes/PreviewRoute";
import EducationListRoute from './routes/EducationListRoute'
import EducationRoute from './routes/EducationRoute'






import LandingPage from "./webRoutes/LandingPage"
import UserLogin from "./webRoutes/UserLogin"
import UserPrivateRoutes from "./webRoutes/UserPrivateRoutes"
import UserDashboard from "./webRoutes/UserDashboard"
import MagazineView from "./webRoutes/MagazineView"
import NotFound from "./webRoutes/NotFound"

const router = createBrowserRouter([

  // Web
  {
    path: "/",
    children: [
      {
        index: true,
        element: <LandingPage />
      },
      {
        path: "login",
        element: <UserLogin />,
      },
      {
        path: "user",
        element: <UserPrivateRoutes />,
        children: [
          {
            index: true,
            element: <UserDashboard />
          }, {
            path: "magazine/:magazineID",
            element: <MagazineView />

          }
        ]
      },
    ]
  },

  // Dashboard
  {
    path: "/admin",
    children: [
      {
        element: <PrivateRoute />,
        children: [
          {
            index: true,
            element: <Home />
          },
          {
            path: 'user',
            children: [
              {
                index: true,
                element: <UserListRoute />,
              },
              {
                path: 'create',
                element: <UserRoute />
              },
              {
                path: ':userID',
                element: <UserRoute />
              },
            ]
          },
          {
            path: 'magazine',
            children: [
              {
                index: true,
                element: <MagazineListRoute />,
              },
              {
                path: 'create',
                element: <MagazineRoute />
              },
              {
                path: ':magazineID',
                element: <MagazineRoute />
              },
            ]
          },
          {
            path: 'education',
            children: [
              {
                index: true,
                element: <EducationListRoute />,
              },
              {
                path: 'create',
                element: <EducationRoute />
              },
              {
                path: ':educationID',
                element: <EducationRoute />
              },
            ]
          },
          {
            path: 'preview/:magazineID',
            element: <PreviewRoute />
          }
        ]
      },
      {
        path: "login",
        element: <Login />,
      },
    ]
  },
  {
    path: "*",
    element: <NotFound />
  },
]);

const App = () => {

  return <RouterProvider router={router} />
}


export default App
