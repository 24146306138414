
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export default function Loading({ open, msg }) {

  const handleClose = (e) => {
    console.log(e)
  };

  return (
    <div style={{
      zIndex: 1
    }}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, position: "absolute" }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress size={150} color="inherit" />
        {msg? 
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          
          <Typography variant="subtitle2" component="div" color={"#fff"}>
            {msg}
          </Typography>
        </Box>: null}
      </Backdrop>
    </div >
  );
}