import { useState, useEffect } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import API from '../api/public'

const PrivateRoutes = () => {
    const [user, setUser] = useState(null)
    const [loading, setLoading] = useState(true)
    
    useEffect(()=>{
        setLoading(true);
        API.user.authorise().then((user)=>{
            setUser(user)
            setLoading(false);
        }).catch(error=>console.log(error))
    },[])

    if(loading) return <div>Loading ... </div>
    return (
        user ? <Outlet /> : <Navigate to="/login" />
    )
}

export default PrivateRoutes