import {createContext, useState} from "react";

export const TitleContext = createContext();

export function TitleContextProvider({ children }) {
  const [title, setTitle] = useState("");
  const [openDrawer, setOpenDrawer] = useState(0)

  const defaultContext = {
    title,
    setTitle,
    openDrawer, 
    setOpenDrawer
  };
  
  return (
    <TitleContext.Provider value={defaultContext}>
      {children}
    </TitleContext.Provider>
  );
}

