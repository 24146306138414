export const POST_OPTS = ContentType => {
    
    const options = {
        method: 'POST',
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            // 'Authorization': `Bearer ${getToken()}`,
            'Content-Type': ContentType || 'application/json',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url   
    }

    if(ContentType == "multipart/form-data") {
        delete options.headers['Content-Type']
    } 
    return  options
}

export  const getToken = () => {
    return localStorage.getItem('admin_token');
}

export const setToken = (token) => {
    return localStorage.setItem('admin_token', token);
}


export default {
    
}