import {setToken, getToken, POST_OPTS } from './network'
import { PRIVATE_API_ENDPOINT as API_ENDPOINT } from '../../constants/url.js'

export const get = async () => {
    try {
        const response = await fetch(`${API_ENDPOINT}/user/getList`, POST_OPTS());
        return await response.json();    
    } catch (error) {
        console.error(error)
    }
}
export const getByID = async (id) => {
    try {
        const response = await fetch(`${API_ENDPOINT}/user/getByID`, {
            body: JSON.stringify({ id }),
            ...POST_OPTS()
        });
        return await response.json();    
    } catch (error) {
        console.error(error)
    }
}
export const create = async (userPayload) => {
    try {
        const response = await fetch(`${API_ENDPOINT}/user/create`, {
            body: JSON.stringify({ payload: {...userPayload} }),
            ...POST_OPTS()
        });
        return await response.json();
    } catch (error) {
        console.error(error)
    }
}
export const update = async (id, userPayload) => {
    try {
        const response = await fetch(`${API_ENDPOINT}/user/update`, {
            body: JSON.stringify({id,  payload:userPayload }),
            ...POST_OPTS()
        });
        return await response.json();
    } catch (error) {
        console.error(error)
    }
}
export const remove = async (id) => {
    try {
        const response = await fetch(`${API_ENDPOINT}/user/remove`, {
            body: JSON.stringify({id}),
            ...POST_OPTS()
        });
        return await response.json();
    } catch (error) {
        console.error(error)
    }
}


export default {
    get,
    getByID,
    create,
    update,
    remove
}