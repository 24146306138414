import API from '../api/private'
import React, { Fragment, useMemo, useEffect, useState, useContext } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { TitleContext } from "../context/TitleContext";

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';



import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import StarBorderIcon from '@mui/icons-material/StarBorder';

import UploadFile from "../components/UploadFile"

import Loading from "../components/Loading"

import { range, rangeRoman, rangeOrdinal } from '../helpers/arrayGenerator.js'

export default (props) => {

    const { setTitle } = useContext(TitleContext);

    const location = useLocation();
    const { magazineID } = useParams();
    const navigate = useNavigate();

    const [magazine, setMagazine] = useState({
        name: "",
        title: "",
        description: "",
        active: true,
        PDF: null,
        cover: null,
        info: {
            season: '', //['PROLJEĆE, LJETO','JESEN', 'ZIMA']
            year: '',
            number: '',
            collection: '',
        }
    });

    const [loading, setLoading] = useState(false);
    const [uploadFile, setUploadFile] = useState(false);

    useEffect(() => {
        if (magazineID) {
            setLoading(true)
            API.magazine.getByID(magazineID).then(magazine => {
                setMagazine(magazine);
                setTitle(magazine.name)
            })
                .catch(e => console.log(e))
                .finally(() => { setLoading(false) })
        }
        else {
            setTitle("Create new magazine")
        }
    }, [magazineID])


    const onNameChanged = (event) => {
        setMagazine({
            ...magazine,
            name: event.target.value
        })
    }
    const onTitleChanged = (event) => {
        setMagazine({
            ...magazine,
            title: event.target.value
        })
    }
    const onDescriptionChanged = (event) => {
        setMagazine({
            ...magazine,
            description: event.target.value,
        });
    }
    const onFileChanged = (file) => {
        setUploadFile(file)
        setMagazine({
            ...magazine,
            PDF: null,
            cover: null
        });
    }

    const onInfoChanged = (payload) => {
        setMagazine({
            ...magazine,
            info: {
                ...magazine.info,
                ...payload
            }
        })
    }


    const handleCreate = () => {
        setLoading(true)
        API.magazine.create({ uploadFile, magazine })
            .then(({ magazine }) => {
                navigate(`../${magazine.id || magazine._id}`)
            }).catch(e => console.log(e))
    }
    const handleRemove = () => {
        setLoading(true)
        API.magazine.remove(magazine.id || magazine._id)
            .then(({ magazine }) => {
                navigate(`../`)
            }).catch(e => console.log(e))
    }
    const handleUpdate = () => {
        setLoading(true)
        API.magazine.update(({ uploadFile, magazine }))
            .then(({ magazine }) => {
                setMagazine(magazine);
                setUploadFile(false);
                setLoading(false)
            }).catch(e => console.log(e))
    }
    const handlePreviewMagazine = () => {
        // if (book.generatingPages == false && book.bookPages) {
        navigate(`../../preview/${magazine.id || magazine._id}`)
        // }
    }




    return (<Grid container spacing={3}>
        <Loading open={loading} />
        {/* <Loading open={book.generatingPages} msg={`Generating pages`} /> */}


        {/* ---------------------------------------------------------------- */}

        <Grid item container xs={12} spacing={3}>
            <Grid item container xs={8}>
                <Paper
                    elevation={3}
                    sx={{
                        width: '100%',
                        maxWidth: '100%',
                        m: 1,
                        p: 2
                    }}
                >
                    <TextField
                        label="Name"
                        value={magazine.name || ""}
                        onChange={onNameChanged}
                        fullWidth
                        margin="normal"
                        variant="standard"
                    />

                    <TextField
                        label="Title"
                        value={magazine.title || ""}
                        onChange={onTitleChanged}
                        fullWidth
                        margin="normal"
                        variant="standard"
                    />

                    <TextField
                        label="Description"
                        value={magazine.description || ""}
                        onChange={onDescriptionChanged}
                        multiline
                        rows={4}
                        fullWidth
                        margin="normal"
                        variant="standard"
                    />

                    <Grid xs={12} spacing={2}>
                        <Box sx={{
                            display: "flex",
                            alignItems: "center",
                            mt: 2,
                            mb: 2,
                            flexWrap: "wrap",
                            columnGap: "4px",
                            rowGap: "16px"

                        }}>
                            {/* INFO.SEASON */}
                            <FormControl sx={{ justifyContent: "flex-end", alignItems: "center", mr: 2, minWidth: "150px" }}>
                                <InputLabel id="select-season-label">Season</InputLabel>
                                <Select
                                    labelId="select-season-label"
                                    id="season"
                                    value={magazine.info.season}
                                    label="Season"
                                    onChange={(e) => onInfoChanged({ season: e.target.value })}

                                    sx={{ justifyContent: "flex-end", alignItems: "center", mr: 2, minWidth: "150px" }}
                                >
                                    <MenuItem value={"PROLJEĆE"}>PROLJEĆE</MenuItem>
                                    <MenuItem value={"LJETO"}>LJETO</MenuItem>
                                    <MenuItem value={"JESEN"}>JESEN</MenuItem>
                                    <MenuItem value={"ZIMA"}>ZIMA</MenuItem>
                                </Select>
                            </FormControl>

                            {/* INFO.YEAR */}
                            <FormControl sx={{ justifyContent: "flex-end", alignItems: "center", mr: 2, minWidth: "150px" }}>
                                <InputLabel id="select-year-label">Year</InputLabel>
                                <Select
                                    labelId="select-year-label"
                                    id="year"
                                    value={magazine.info.year}
                                    label="Year"
                                    onChange={(e) => onInfoChanged({ year: e.target.value })}

                                    sx={{ justifyContent: "flex-end", alignItems: "center", mr: 2, minWidth: "150px" }}
                                >
                                    {range(20, 2017).map(i => <MenuItem value={i}>{i}</MenuItem>)}
                                </Select>
                            </FormControl>


                            {/* INFO.collection */}
                            <FormControl sx={{ justifyContent: "flex-end", alignItems: "center", mr: 2, minWidth: "150px" }}>
                                <InputLabel id="select-collection-label">Collection</InputLabel>
                                <Select
                                    labelId="select-collection-label"
                                    id="collection"
                                    value={magazine.info.collection}
                                    label="Collection"
                                    onChange={(e) => onInfoChanged({ collection: e.target.value })}

                                    sx={{ justifyContent: "flex-end", alignItems: "center", mr: 2, minWidth: "150px" }}
                                >
                                    {rangeRoman(20, 1).map(i => <MenuItem value={i}>{i}</MenuItem>)}
                                </Select>
                            </FormControl>


                            {/* INFO.number */}
                            <FormControl sx={{ justifyContent: "flex-end", alignItems: "center", mr: 2, minWidth: "150px" }}>
                                <InputLabel id="select-number-label">Number</InputLabel>
                                <Select
                                    labelId="select-number-label"
                                    id="number"
                                    value={magazine.info.number}
                                    label="Number"
                                    onChange={(e) => onInfoChanged({ number: e.target.value })}

                                    sx={{ justifyContent: "flex-end", alignItems: "center", mr: 2, minWidth: "150px" }}
                                >
                                    {range(100, 1).map(i => <MenuItem value={i}>{`${i}.`}</MenuItem>)}
                                </Select>
                            </FormControl>

                            <FormGroup sx={{ justifyContent: "flex-end", alignItems: "center", mr: 2 }}>
                                <FormControlLabel control={<Switch defaultChecked checked={magazine.active} onChange={e => setMagazine({ ...magazine, active: e.target.checked })} />} label="Active" />
                            </FormGroup>
                        </Box>
                    </Grid>


                    <UploadFile acceptType="application/pdf"
                        setFile={onFileChanged}
                        file={magazine.PDF || uploadFile}
                        cover={magazine.cover || null} />

                    {/* {
                        magazine.PDF && magazine.cover ? <CoverView cover={magazine.cover} /> : null
                    } */}



                </Paper>
            </Grid>
            <Grid item container xs={4} sx={{}}>
                <Paper
                    elevation={3}
                    sx={{
                        width: '100%',
                        maxWidth: '100%',
                        m: 1,
                        p: 2
                    }}>

                    {/* <Box sx={{
            display: magazine && magazine.PDF ? "flex" : "none",
            justifyContent: "center"
          }}>
            <Button variant="contained" onClick={handlePreviewMagazine}> Preview Magazine </Button>
          </Box> */}

                    {
                        magazineID ?
                            <Stack direction="column" spacing={2}>
                                {magazine && magazine.PDF ? <Button variant="contained" onClick={handlePreviewMagazine}> Preview Magazine </Button> : null}
                                <Button variant="contained" color="success" onClick={handleUpdate}> SAVE </Button>
                                <Button variant="contained" color="error" onClick={handleRemove}> REMOVE </Button>


                            </Stack>
                            :
                            <Stack direction="column" spacing={2}>
                                <Button variant="contained" color="success" onClick={handleCreate}> CREATE </Button>
                            </Stack>
                    }

                </Paper>


            </Grid>


        </Grid>
    </Grid>)

}




