import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate, useOutletContext, useLocation } from "react-router-dom";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";


export default ({ list, keys }) => {

  

  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  useEffect(() => { setPage(0) }, [list])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  

  const TableCellList = (item, keys) => {

    const getCellValue = (item, key) => { 
      if(typeof key == 'string')  {
        return item[key].toUpperCase(); 
      } else if (typeof key == 'object')  {
          // For now we can only go two depths example ['keyA', ['keyB', 'keyC']] 
         if(Array.isArray(key)) {
            let [f, s] = key;
           return item[f][s]
         }
      }
      return null
      
    }
    
    return keys.map(key => 
      item[key] ?
      <TableCell
        component="th"
        id={key}
        scope="row"
        padding="none">
        {getCellValue(item, key)}
      </TableCell>:
      <TableCell
      component="th"
      id={key}
      scope="row"
      padding="none">
      {""}
    </TableCell>
    )
  }
    

  return (<Fragment>
    <TableContainer>
      <Table
        aria-labelledby="tableTitle"
        size="medium"
        aria-label="enhanced table"
      >
        <TableBody>
          {list
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((item, index) => {

              // ROWS
              return (
                <TableRow
                  hover
                  tabIndex={-1}
                  key={index}
                >
                  <TableCell padding="checkbox">{index+1}</TableCell>

                  {TableCellList(item,keys)}


                  {/* <TableCell align="right">{`${group.productList.length} objects`}</TableCell> */}
                  {/* <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell> */}


                  <TableCell size="small" align="right">
                    <Button
                      size="small"
                      variant="outlined"
                      onClick={()=>{
                        navigate(`${item.id || item._id}`)
                      }}
                    >
                      Edit
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
    <TablePagination
      rowsPerPageOptions={[5, 10, 25]}
      component="div"
      count={list.length}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  </Fragment>)
}

