import API from '../api/private'

import React, { useEffect, useState, useContext } from "react";

import { useNavigate, useOutletContext, useLocation } from "react-router-dom";

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import ListView from '../components/ListView'


import { TitleContext } from "../context/TitleContext";

const EducationListRoute = (props) => {

    const [educationList, setEducationList] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    const { setTitle } = useContext(TitleContext);

    useEffect(() => {
        API.education.get().then(educationList => {
            console.log("handleGet", educationList)
            if (educationList) {
                setEducationList(educationList)
            }
        }).catch(e => console.log(e));

        setTitle("Educations");
    }, [])


    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                {educationList.length > 0 ?
                    <ListView list={educationList.reverse()} keys={['name']}/> :
                    <Box sx={{
                        height: 150,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                        <Typography variant="subtitle1" component="div">
                            List is empty!!!
                        </Typography>
                    </Box>
                }
            </Grid>
            <Grid item xs={12}>
                <Button variant="contained" onClick={() => { navigate("create") }}> Create New Education</Button>
            </Grid>
        </Grid>
    )

}


EducationListRoute.displayName = "EducationListRoute"
export default EducationListRoute