import { setToken, getToken, POST_OPTS } from './network'
import { PRIVATE_API_ENDPOINT as API_ENDPOINT } from '../../constants/url.js'

export const get = async () => {
    try {
        const response = await fetch(`${API_ENDPOINT}/magazine/getList`, POST_OPTS());
        return await response.json();
    } catch (error) {
        console.error(error)
    }
}
export const getByID = async (id) => {
    try {
        const response = await fetch(`${API_ENDPOINT}/magazine/getByID`, {
            body: JSON.stringify({ id }),
            ...POST_OPTS()
        });
        return await response.json();
    } catch (error) {
        console.error(error)
    }
}
export const create = async ({magazine, uploadFile}) => {
    
    const formData = new FormData();
    formData.append('payload', JSON.stringify({ ...magazine }))

    if (uploadFile) {
        formData.append('file', uploadFile)
    }
    try {
        const response = await fetch(`${API_ENDPOINT}/magazine/create`, {
            body: formData,
            ...POST_OPTS('multipart/form-data')
        });
        return await response.json();
    } catch (error) {
        console.error(error)
    }
}
export const update = async ({magazine, uploadFile}) => {

    const formData = new FormData();
    formData.append('id', magazine.id || magazine._id)
    formData.append('payload', JSON.stringify({...magazine}))

    if (uploadFile) {
        formData.append('file', uploadFile)
    }
    try {
        const response = await fetch(`${API_ENDPOINT}/magazine/update`, {
            body: formData,
            ...POST_OPTS('multipart/form-data')
        });
        return await response.json();
    } catch (error) {
        console.error(error)
    }
}
export const remove = async (id) => {
    try {
        const response = await fetch(`${API_ENDPOINT}/magazine/remove`, {
            body: JSON.stringify({id}),
            ...POST_OPTS()
        });
        return await response.json();
    } catch (error) {
        console.error(error)
    }
}

export default {
    get,
    getByID,
    create,
    update,
    remove
}