import admin from "./admin"
import user from "./user"
import magazine from "./magazine"
import education from "./education"


export default  {
    admin,
    user, 
    magazine,
    education
}