import { useEffect, useMemo, useState } from "react";
import { useDropzone } from 'react-dropzone';

import CloseIcon from '@mui/icons-material/Close';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';



import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import StarBorderIcon from '@mui/icons-material/StarBorder';

import DeleteIcon from '@mui/icons-material/Delete';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import PDFLogo from "../PDF-logo.png"

import { converPath } from '../helpers/pathGenerator.js'


const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const focusedStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};


const UploadFile = ({ acceptType, file, setFile, cover }) => {

    const [error, setError] = useState();

    const {
        acceptedFiles,
        fileRejections,
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject
    } = useDropzone({
        accept: {
            "application/pdf": []
        },
        maxFiles: 1
    });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    useEffect(() => {
        if (acceptedFiles.length) {
            setFile(acceptedFiles[0])
            setError(null);
        } else if (fileRejections.length) {
            setFile(null);
            setError(fileRejections[0])
        }
    }, [
        acceptedFiles,
        fileRejections
    ])

    const RejectFileView = fileRejections.map(({ file, errors }) => {
        return (
            <div style={{ color: "red" }}>
                {file.path} - {file.size} bytes
                <ul>
                    {errors.map(e => <li key={e.code}>{e.message}</li>)}
                </ul>

            </div>
        )
    });

    return (
        <section className="container">

            {file ? <CoverView file={file} setFile={setFile} cover={cover} /> :
                <div {...getRootProps({ style })}>
                    <input {...getInputProps()} />
                    <p>Upload Magazine PDF</p>
                    {error ? <aside>
                        {RejectFileView}
                    </aside> : null
                    }
                </div>
            }

        </section>
    );
}

export default UploadFile;







const CoverView = ({ file, setFile, cover }) => {

   

    return (
        <ImageList cols={1}   sx={{ width: 250, height: 350 }} >

            {
                cover ?
                <ImageListItem >
                <img
                    src={`${converPath(cover.path)}?w=255&h=330&fit=crop&auto=format`}
                    srcSet={`${converPath(cover.path)}?w=255&h=330&fit=crop&auto=format&dpr=2 2x`}
                    alt={cover.name}
                    loading="lazy"
                />
                <ImageListItemBar
                        position="top"
                        title={"COVER"}
                        actionIcon={
                            <IconButton
                                sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                aria-label={`Remove`}
                                onClick={() => { setFile(null) }}
                            >
                                <CloseIcon />
                            </IconButton>
                        }
                    />
            </ImageListItem>:

            <ImageListItem >
                <img
                    src={PDFLogo}
                    loading="lazy"
                />
                <ImageListItemBar
                        position="top"
                        actionIcon={
                            <IconButton
                                sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                aria-label={`Remove`}
                                onClick={() => { setFile(null) }}
                            >
                                <CloseIcon />
                            </IconButton>
                        }
                    />
            </ImageListItem>
            
            }
        </ImageList>
    );
}


const AcceptFileView = ({ file, setFile, coverPage }) =>

    <Paper sx={{
        minWidth: 175,
        minHeight: 120,
        display: 'flex',
        alignItems: 'center',
        m: 2,
        p: 1
    }}>
        <Typography sx={{
            fontSize: 20,
            width: "100%",
            m: 2
        }}
            align="center"
            color="text.secondary"
            gutterBottom>
            <Box sx={{
                height: 150,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                {coverPage ? <img
                    src={`${converPath(coverPage.path)}?w=255&h=330&fit=crop&auto=format`}
                    srcSet={`${converPath(coverPage.path)}?w=255&h=330&fit=crop&auto=format&dpr=2 2x`}
                    loading="lazy"
                    alt={coverPage.name}
                    style={{ width: 75, margin: 15 }}
                /> :
                    <PictureAsPdfIcon />}
                <Box sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "calc(100% - 160px)",
                    textAlign: "start",
                    flexGrow: 2
                }} >
                    {file.constructor == File ? file.name : file.filename}
                </Box>
                <Button sx={{
                    alignSelf: "flex-end",
                    flexGrow: 1,
                    maxWidth: 120
                }}
                    onClick={() => { setFile(null) }}
                    variant="outlined" startIcon={<DeleteIcon />}>
                    Delete
                </Button>
            </Box>
        </Typography>
    </Paper>


const AcceptFileViewJSON = ({ file }) => {
    var fileObject = null
    if (file.constructor == File) {
        fileObject = {
            'lastModified': file.lastModified,
            'lastModifiedDate': file.lastModifiedDate,
            'name': file.name,
            'size': file.size,
            'type': file.type
        };
    } else if (file.constructor == Object) {
        fileObject = file
    }
    return <div><pre>{JSON.stringify(fileObject, null, 2)}</pre></div>
}



function bytesToSize(bytes) {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes == 0) return '0 Byte';
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
}