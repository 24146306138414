import { PUBLIC_API_ENDPOINT as API_ENDPOINT } from '../../constants/url.js'


export  const getToken = () => {
    return localStorage.getItem('user_token');
}

export const setToken = (token) => {
    return localStorage.setItem('user_token', token);
}

export default {

    user: {
        login: async (email, password) => {
            const options = {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json',
                },
                redirect: 'follow', // manual, *follow, error
                referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                body: JSON.stringify({ email, password }) // body data type must match "Content-Type" header
            }
            try {

                const response = await fetch(`${API_ENDPOINT}/user/login`, options);

                if(response.status == 401) {
                    return {
                        error: "Krivi email ili lozinka"
                    }                        
                }
                const { user, token } = await response.json();

                if (token && user) {
                    setToken(token)
                    return {user}
                }
            } catch (error) {
                console.error(error);
                return null;
            }
        },
        authorise: async () => {
            const token = getToken();
            if (!token) return null;
    
            const options = {
                method: 'POST',
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                redirect: 'follow', // manual, *follow, error
                referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            }
            try {
                const response = await fetch(`${API_ENDPOINT}/user/authorise`, options);
    
                const { user } = await response.json();
                return user;
            } catch (error) {
                console.error(error);
                return null;
            }
    
        },
        getMagazineList: async () => {
            const token = getToken();
            if (!token) return null;
    
            const options = {
                method: 'POST',
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                redirect: 'follow', // manual, *follow, error
                referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            }
            try {
                const response = await fetch(`${API_ENDPOINT}/user/getMagazineList`, options);
    
                const { magazines } = await response.json();
                return magazines;
            } catch (error) {
                console.error(error);
                return null;
            }
        },
        logout: async () => {
            setToken(null)
        }
    },
    magazine: {
        getList: async() => {
            const options = {
                method: 'POST',
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    // 'Authorization': `Bearer ${getToken()}`,
                    'Content-Type': 'application/json',
                },
                redirect: 'follow', // manual, *follow, error
                referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url   
            }

            try {
                const response = await fetch(`${API_ENDPOINT}/magazine/getList`, options);
                return await response.json();
            } catch (error) {
                console.error(error);
                return null;
            }
        },
        get: async(id) => {
            const token = getToken();
            if (!token) return null;
    
            const options = {
                method: 'POST',
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                redirect: 'follow', // manual, *follow, error
                referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                body: JSON.stringify({ id }) // body data type must match "Content-Type" header
            }
            try {
                const response = await fetch(`${API_ENDPOINT}/magazine/get`, options);
    
                return await response.json();
            } catch (error) {
                console.error(error);
                return null;
            }
        }
    }
   

}