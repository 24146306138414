import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useNavigate, Link } from 'react-router-dom';
import { converPath } from '../helpers/pathGenerator'

export default function MediaCard({magazine}) {

  const navigate = useNavigate()
  if(!magazine.cover) return <div>No cover</div>

  return (
    <Card sx={{ maxWidth: 345, m: 1, position:"relative", display:"flex", flexDirection:"column", justifyItems:"center" }}>
      <CardMedia
        component="img"
        image={converPath(magazine.cover.path)}
      />
      <CardContent>
        <Typography gutterBottom variant="h6" component="div">
          {magazine.title}
        </Typography>
        <Typography variant="body1" color="text.primary">
          TEMA BROJA:
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {magazine.description}
        </Typography>
      </CardContent>
      <CardActions sx={{alignSelf: "center", flex: 1, display: "flex", alignItems: "flex-end"}}>
      <Button size="small" variant="outlined">
        <Link style={{textDecoration: "none", color: "inherit"}} to={`/user/magazine/${magazine.id || magazine.id }`} target="_blank" rel="noopener noreferrer">Pogledaj časopis</Link>
      </Button>
      {/* <Button size="small" onClick={()=>{ navigate(`/user/magazine/${magazine.id || magazine.id }`)}}>Pogledaj časopis</Button> */}
      </CardActions>
    </Card>
  );
}
