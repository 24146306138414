import React, { useRef, useEffect, useState, useContext, useCallback } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import API from '../api/public'
import { API_URL as HOST_ADDRESS } from "../constants/url.js"
// import $ from "jquery";



export default () => {
    const location = useLocation();
    const { magazineID } = useParams();
    const [magazine, setMagazine] = useState(null);


    
    useEffect(() => {
        if (magazineID) {
            //   setLoading(true)
            API.magazine.get(magazineID).then(magazine => {
                setMagazine(magazine);

               
                
                window.$("#container").flipBook({
                    pdfUrl: `${HOST_ADDRESS}/${magazine.PDF.path}`,
                    btnPrint: false,
                    btnShare: false,
                    btnToc: false,
                    btnThumbs: false,
                    btnDownloadPages: false,
                    btnDownloadPdf: false,
                    btnBookmark: false
                })
            
            })
            .catch(e => console.log(e))
            .finally(() => { })
        }
    }, [magazineID])



    

    return <div id="container" style={{
        
    // display: "flex",
    
    // width: "100%",
    
    // flexDirection: "row",
    // marginTop: "-24px",
    // width: "calc(100% + 24px)",
    // marginLeft: "-24px"
        position:"absolute",
        top:0,
        left: 0,
        right:0,
        bottom:0,
    }}></div>
    // if (book)
    //     return <BookView book={book} />
    // else
    //     return <div>{`Loading Book <${bookID}> please wait...`}</div>


}
