import { useState, useEffect } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import MainLayout from "./MainLayout"
import { AdminContextProvider } from "../context/AdminContext";
import { TitleContextProvider } from "../context/TitleContext";

import API from '../api/private'

const PrivateRoutes = () => {
    
    const [user, setUser] = useState(null)
    const [loading, setLoading] = useState(true)
    
    useEffect(()=>{
        setLoading(true);
        API.admin.authorise().then((user)=>{
            setUser(user)
            setLoading(false);
        }).catch(error=>console.log(error))
    },[])

    if(loading) return <div>Loading ... </div>


    return (
        user ? (
            <AdminContextProvider>
            <TitleContextProvider>
                <MainLayout >
                    <Outlet />
                </MainLayout>
                </TitleContextProvider>
            </AdminContextProvider>) : <Navigate to="login" />
    )
}

export default PrivateRoutes