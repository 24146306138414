let BASE_URL = ""

if(document.location.origin.includes("apartmanplus.com")) {
    BASE_URL = "https://api.apartmanplus.com"
} else {
    BASE_URL = "http://localhost:3001"
}

console.log(`BASE_URL -> ${BASE_URL}`)

export const API_URL = BASE_URL;
export const PRIVATE_API_ENDPOINT = `${API_URL}/api/v1/private`;
export const PUBLIC_API_ENDPOINT = `${API_URL}/api/v1/public`;

export default {
    
}