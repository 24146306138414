import {useState} from 'react';
import { useNavigate, useOutletContext, useLocation } from "react-router-dom";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Unstable_Grid2';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

import APLogo from '../apartman-plus-logo.jpeg'


import API from '../api/private'

const AdminLogin = () => {

    
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const navigate = useNavigate()

    const login = (email, password) => {
        API.admin.login(email, password)
        .then(({user, error}) => {
            if(user) {
                navigate('/admin')
            } else if(error) {
                setError(error)
            }
        }).catch(e => console.log(e))
    }



    const onEmailChange = (e)=> {
        setEmail(e.target.value)
        setError("")
    }
    const onPasswordChange = (e)=> {
        setPassword(e.target.value)
        setError("")
    }

    return (
        <Container maxWidth="sm">

            <Box sx={{
                  display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    m:2
                    }}>

                    <img src={APLogo} className="logo" alt="logo" style={{maxHeight:200}}/>
                </Box>

            {/* <Paper elevation={3} sx={{mt: 8, p:4}}> */}
            <Box
                component="form"
                sx={{
                    '& > :not(style)': { m: 1, mt:8, maxWidth: '100%' },
                }}
                noValidate
                autoComplete="off"
            >
                <Stack spacing={4}>
                    <TextField id="user-email" fullWidth value={email} onChange={onEmailChange} label="Korisničko ime ili email adresa *" variant="standard" />
                    <TextField id="user-password" type="password" value={password} onChange={onPasswordChange} label="Zaporka *" variant="standard" />
                    {error? <span style={{
                        color: "red",
                        marginTop: "10px",
                        fontSize: "12px"
                    }}> {error}</span> : null}
                    <Button variant="outlined"onClick={()=>{ login(email, password) }}>Prijava</Button>
                </Stack>
            </Box>
            {/* </Paper> */}
        </Container>
    )



}


export default AdminLogin