import API from '../api/private'
import React, { useEffect, useState, useContext } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { TitleContext } from "../context/TitleContext";

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import Loading from "../components/Loading"
import TransferList from '../components/TransferList';
import TableTransfer from '../components/TableTransfer'

export default (props) => {
  const location = useLocation();
  const {userID} = useParams();
  const navigate = useNavigate();
  const [user, setUser] = useState({
    name: "",
    email: "",
    password: "",
    magazines: []
  });
  const [magazineList, setMagazineList] = useState([]);
  const [userMagazineList, setUserMagazineList] = useState([]);



  const {setTitle} = useContext(TitleContext);

  const [loading, setLoading] = useState(false);
  
  useEffect(()=> {
    setLoading(true)
    API.magazine.get().then(magazineList => {
      setMagazineList(magazineList)
    }).finally(()=>{
      setLoading(false)
    })
  }, [])

  useEffect(()=>{
    if(userID) {
      setLoading(true)
      API.user.getByID(userID).then(user => {
        setUser(user);
        setTitle(user.name)
      }).finally(()=>{
        setLoading(false)
      })
    }
    else {
      setTitle("Create new user")
    }
  },[userID])
  

  useEffect(()=> {
    console.log(user)
    
  }, [user])
  
  const filterMagazineList = (magazineList, magazineListID, exclude) => {
    const magazineList_f = [];
    magazineList.forEach(magazine => {
      if(magazineListID.includes(magazine.id || magazine._id) && !exclude)
      magazineList_f.push(magazine)
      else if(!magazineListID.includes(magazine.id || magazine._id) && exclude) {
        magazineList_f.push(magazine)
      }
    })
    return magazineList_f;
  }

  const removeMagazine = (magazineList) => {
    setUser({...user, 
      magazines: user.magazines.filter(productID =>
        !magazineList.map(m => m.id || m._id).includes(productID))
    }) 
  }

  const addMagazine = magazineList => {
    setUser({...user, 
      magazines: [...user.magazines, ...magazineList.map(m => m.id || m._id)]
   }) 
  } 

  const handleCreate = () => {
    setLoading(true)
    API.user.create(user )
    .then(({ user }) => {
        navigate(`../${user.id || user._id}`)
    }).catch(e => console.log(e))
  }
  const handleRemove = () => {
    setLoading(true)
    API.user.remove(userID)
      .then(({ user }) => {
        navigate(`../`)
      })
  }
  const handleUpdate = () => {
    setLoading(true)

    API.user.update(userID, user)
      .then(({user}) => {
        setUser(user);
        setLoading(false)
      }).catch(e => console.log(e))
  }

  <Loading open={loading} />
  

  return (
    <Grid container spacing={3}>
      <Grid item container xs={8}>
        <Paper 
        elevation={3}
        sx={{
          width: '100%',
          maxWidth: '100%',
          m: 1,
          p: 2
        }}>
          <TextField
            label="Name"
            value={user.name}
            onChange={(e) => {
              setUser({
                ...user,
                name: e.target.value,
              });
            }}
            fullWidth
            margin="normal"
            variant="standard"
          />
          <TextField
            label="Email"
            value={user.email}
            onChange={(e) => {
              setUser({
                ...user,
                email: e.target.value,
              });
            }}
            fullWidth
            margin="normal"
            variant="standard"
          />
          <TextField
            label="Password"
            type="password"
            value={user.password}
            onChange={(e) => {
              setUser({
                ...user,
                password: e.target.value,
              });
            }}
            fullWidth
            margin="normal"
            variant="standard"
          />
        </Paper>
      </Grid>
   
   
      <Grid item container xs={4} sx={{}}>
                <Paper
                    elevation={3}
                    sx={{
                        width: '100%',
                        maxWidth: '100%',
                        m: 1,
                        p: 2
                    }}>

                    {/* <Box sx={{
            display: magazine && magazine.PDF ? "flex" : "none",
            justifyContent: "center"
          }}>
            <Button variant="contained" onClick={handlePreviewMagazine}> Preview Magazine </Button>
          </Box> */}

{
          userID ?
            <Stack direction="column" spacing={2}>
              <Button variant="contained" color="success"  onClick={handleRemove}> REMOVE </Button>
              <Button variant="contained" color="error"  onClick={handleUpdate}> SAVE </Button>
            </Stack>
            :
            <Stack direction="column" spacing={2}>
              <Button variant="contained" color="success" onClick={handleCreate}> CREATE </Button>
            </Stack>
        }

                </Paper>


            </Grid>
   
      <Grid item container xs={12}>
        <TransferList 
        left = {filterMagazineList(magazineList, user.magazines)}
        right = {filterMagazineList(magazineList, user.magazines, true)}
        setLeft = {l => removeMagazine(l)}
        setRight = {r => addMagazine(r)}
        />

        {/* <TableTransfer /> */}
        </Grid>
    </Grid>
  )

}