import React, { Fragment, useEffect, useState, useContext } from "react";

import { useNavigate, Link, useOutletContext, useLocation } from "react-router-dom";

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ListSubheader from '@mui/material/ListSubheader';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import APLogo from '../apartman-plus-logo.jpeg'

import API from '../api/public'


export default ({user}) => {
    const navigate = useNavigate();

    
    return (
        <Box sx={{
            display: "flex",
            height: "75px",
            background: "linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5))",
            alignItems: "center",
            mt: 1,
            ml: 4,
            mr: 4
        }}>
            <Box sx={{
                display: "flex",
                justifyContent: "flex-start"
            }}>
                <Link to={'/'}>
                    <img src={APLogo} className="logo" alt="logo" style={{
                        maxHeight: "55px"
                    }} />
                </Link>
            </Box>
            <Box sx={{
                display: "flex",
                flexGrow: 1,
                justifyContent: "flex-end"
            }}>
                {user ? <AdminMenu/> : <Button variant="outlined" onClick={()=>navigate('login')}>Login</Button>}
                
            </Box>
        </Box>
    )
}



function AdminMenu(props) {

    const [anchorEl, setAnchorEl] = useState(null);

    const navigate = useNavigate()

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const logout = async () => {
        await API.user.logout();
        handleClose();
        window.location.reload();
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Fragment>
            <IconButton size="large" color="secondary" sx={{color: "#5d2e91"}} onClick={handleClick}>
                <AccountCircleIcon fontSize="large"/>
            </IconButton>
            <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                <MenuItem onClick={()=>navigate('/user')}>Moj račun</MenuItem>
                <MenuItem onClick={logout}>Odjava</MenuItem>
            </Menu>
        </Fragment>
    );
}
