import { setToken, getToken } from './network'

import { PRIVATE_API_ENDPOINT as API_ENDPOINT } from '../../constants/url.js'

export const authorise = async () => {
    const token = getToken();
    if (!token) return null;

    const options = {
        method: 'POST',
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Authorization': `Bearer ${token}`
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    }
    try {
        const response = await fetch(`${API_ENDPOINT}/admin/authorise`, options);

        const { user } = await response.json();
        return user;
    } catch (error) {
        console.error(error);
        return null;
    }

}


export const login = async (email, password) => {
    const options = {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify({ email, password }) // body data type must match "Content-Type" header
    }
    try {

        const response = await fetch(`${API_ENDPOINT}/admin/login`, options);

        if(response.status == 401) {
            return {
                error: "Krivi email ili lozinka"
            }                        
        }
        const { user, token } = await response.json();

        if (token && user) {
            setToken(token)
            return {user}
        }
    } catch (error) {
        console.error(error);
        return null;
    }
}
export const logout = async () => {
    setToken(null)
}
// export const create = async () => {
//     console.log("Not implemented yet.")
// }
// export const update = async () => {
//     console.log("Not implemented yet.")
// }


export default {
    authorise,
    login,
    logout, 
}