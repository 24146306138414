import API from '../api/private'
import { v4 as UUID } from 'uuid';
import React, { useEffect, useState, useContext } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { TitleContext } from "../context/TitleContext";
import { useDropzone } from 'react-dropzone';

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';


import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';


import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';

import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import LockIcon from '@mui/icons-material/Lock';


// New 
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment'

// Date
import dayjs from 'dayjs';
import 'dayjs/locale/hr';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DateField } from '@mui/x-date-pickers/DateField';
import { TimeField } from '@mui/x-date-pickers/TimeField';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';


import { DatePicker, TimeClock, TimePicker } from '@mui/x-date-pickers';



// Custom components
import ImageUploadForm from '../components/ImageUploadForm'

import { converPath } from '../helpers/pathGenerator.js'
import toSlug from '../helpers/toSlug'


// import { Editor } from '../components/TextEditor';
import { Editor } from "@tinymce/tinymce-react";

export default (props) => {

  const location = useLocation();
  const { educationID } = useParams();
  const navigate = useNavigate();
  const { setTitle: setAppTitle } = useContext(TitleContext);

  // Meta
  const [id, setID] = useState("")
  const [uuid, setUUID] = useState("")
  const [name, setName] = useState("")
  const [slug, setSlug] = useState("")
  const [slugLocked, setSlugLocked] = useState(false)

  const [date, setDate] = useState(dayjs(new Date()))


  // Status
  const [published, setPublished] = useState(false)
  const [active, setActive] = useState(false)

  // Content
  const [title, setTitle] = useState({ hr: "", en: "" });
  const [shortdesc, setShortdesc] = useState({ hr: "", en: "" });
  const [description, setDescription] = useState({ hr: "", en: "" });

  // Gallery 
  const [gallery, setGallery] = useState([]);
  const [uploadImages, setUploadImages] = useState([])


  //

  const [loading, setLoading] = useState(false);
  const [openDataLangForm, setOpenDataLangForm] = useState(false);





  // API CALLS
  const handleGetByID = async () => {
    API.education.getByID(educationID).then((education) => {

      console.log("handleGetByID", education)

      setID(education._id || education.id)
      // Meta
      setUUID(education.uuid)
      setName(education.name)
      setSlugLocked(true)
      setSlug(education.slug)
      setDate(dayjs(education.date))

      // Status
      setPublished(education.published)
      setActive(education.active)

      // Content
      setTitle(education.title)
      setShortdesc(education.shortdesc)
      setDescription(education.description)

      // Files
      setGallery(education.gallery)
    })
  }

  const handleCreate = () => {

    API.education.create({
      uuid,
      name,
      slug,
      date,
      title,
      shortdesc,
      description,
      gallery,
      published,
      active
    }, uploadImages).then(({ education, error }) => {
      if (error) {
        alert(error)
      }
      else {
        setUploadImages([]);
        navigate(`../${education.id || education._id}`)
      }
    }).catch(e => console.log(e))
  }

  const handleUpdate = () => {
    // setLoading(true)
    API.education.update({
      id,
      uuid,
      name,
      slug,
      date,
      title,
      shortdesc,
      description,
      gallery,
      published,
      active,
      gallery
    }, uploadImages).then(({ education, error }) => {

      if (error) {
        alert(error)
      }
      else {
        // Meta
        setUUID(education.uuid)
        setName(education.name)
        setSlugLocked(true)
        setSlug(education.slug)
        setDate(dayjs(education.date))
        // Status
        setPublished(education.published)
        setActive(education.active)
        // Content
        setTitle(education.title)
        setShortdesc(education.shortdesc)
        setDescription(education.description)
        // Files
        setGallery(education.gallery)
        // App
        setAppTitle(name)
        setUploadImages([]);
        alert("Saved")
      }

    }).catch(e => console.log(e))
      .finally(() => {
        setLoading(false)
      })
  }

  const handleRemove = () => {
    // setLoading(true)
    API.education.remove(educationID)
      .then(({ response }) => {
        navigate(`../`)
      }).catch(e => console.log(e))
      .finally(() => {
        setLoading(false)
      })
  }


  useEffect(() => {
    if (educationID) {
      handleGetByID().then(() => {
        setAppTitle(name)
      })
    } else {
      setUUID(UUID())
      setAppTitle("Add new Education")
    }
  }, [educationID])

  useEffect(() => {
    if (!slugLocked)
      setSlug(toSlug(name))
  }, [name])



  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'hr'}>
      <Grid container sx={{}}>

        <Row style={{
          alignItems: "center",
          justifyContent: "flex-end",
        }}>
          <ButtonControls educationID={educationID}
            handleUpdate={handleUpdate}
            handleCreate={handleCreate}
            handleRemove={handleRemove}
          />
        </Row>
        {/* Row 1 */}
        <Row>
          <Paper variant="outlined" >
            <MetaForm name={name}
              setName={setName}
              slug={slug}
              setSlug={setSlug}
              slugLocked={slugLocked}
              setSlugLocked={setSlugLocked}
              date={date}
              setDate={setDate}
              published={published}
              setPublished={setPublished}
              active={active}
              setActive={setActive}
            />
          </Paper>
        </Row>

        {/* Row 2 */}
        <Row>
          <LangContentForm lang={"hr"}
            open={openDataLangForm}
            onOpenChange={setOpenDataLangForm}
            title={title}
            setTitle={setTitle}
            shortdesc={shortdesc}
            setShortdesc={setShortdesc}
            description={description}
            setDescription={setDescription}
          />
          <LangContentForm lang={"en"}
            open={openDataLangForm}
            onOpenChange={setOpenDataLangForm}
            title={title}
            setTitle={setTitle}
            shortdesc={shortdesc}
            setShortdesc={setShortdesc}
            description={description}
            setDescription={setDescription}
          />
        </Row>
        {/* Row 3 */}
        <Row>
          <ImageGallery
            images={gallery}
            setImages={setGallery}
          />
        </Row>
        {/* Row 4 */}
        <Row>
          <ImageUploadDropzone
            acceptType={{ 'image/*': [] }}
            maxFiles={5 - gallery.length}
            uploadFiles={uploadImages}
            setUploadFiles={setUploadImages}
          />
        </Row>
      </Grid>
    </LocalizationProvider>
  )

}



const MetaForm = ({ name, setName, slug, setSlug, date, setDate, published, setPublished, active, setActive, slugLocked, setSlugLocked }) => {

  return (
    <Grid container sx={{ ml: 2, mr: 2 }}>
      {/* Row 1 */}
      <Row>
        <Grid xs={6}>
          <TextField id="standard-basic" fullWidth label="Name" variant="standard" value={name} onChange={e => setName(e.target.value)} />
        </Grid>
        <Grid xs={6} sx={{ display: "flex", justifyContent: "center", alignItems: "center", ml: 2 }}>
          <DateTimePicker label="Education date" value={date}
            onChange={(newValue) => setDate(newValue)} />
        </Grid>
      </Row>
      {/* Row 2 */}
      <Row>
        <Grid xs={8}>
          <FormControl fullWidth>
            <InputLabel htmlFor="web-url-slug">Web URL</InputLabel>
            <Input
              id="web-url-slug"
              value={slug}
              disabled={slugLocked}
              onChange={e => {
                setSlug(e.target.value)
              }}
              startAdornment={<InputAdornment sx={{ mr: 0 }} position="start"><span style={{ color: "" }}>{`https://apartmanplus.com/edukacije/`}</span></InputAdornment>}
              endAdornment={
                !slugLocked ? null :
                  <InputAdornment sx={{ mr: 0 }} position="start" onClick={() => { setSlugLocked(false) }}><LockIcon /></InputAdornment>
              }
            />
          </FormControl>
        </Grid>
        <Grid xs={4} sx={{ display: "flex", justifyContent: "start", alignItems: "center", ml: 2 }}>

          <FormGroup sx={{ justifyContent: "flex-end", alignItems: "center" }}>
            <FormControlLabel control={<Switch defaultChecked checked={published} onChange={e => setPublished(e.target.checked)} />} label="Published" />
          </FormGroup>
          <FormGroup sx={{ justifyContent: "flex-end", alignItems: "center" }}>
            <FormControlLabel control={<Switch defaultChecked checked={active} onChange={e => setActive(e.target.checked)} />} label="Active" />
          </FormGroup>
        </Grid>
      </Row>
    </Grid>
  )
}


const ButtonControls = ({ educationID, handleCreate, handleUpdate, handleRemove }) => {
  return (<>
    {educationID ?
      <>
        <Button sx={{ ml: 1, mr: 1 }} color="success" variant="contained" onClick={handleUpdate}>Update</Button>
        <Button sx={{ ml: 1, mr: 1 }} color="error" variant="contained" onClick={handleRemove}>Remove</Button>
      </>
      :
      <>
        <Button sx={{ ml: 1, mr: 1 }} color="success" variant="contained" onClick={handleCreate}>Create</Button>
      </>}
  </>
  )
}

const LangContentForm = ({ lang, open, onOpenChange, ...props }) => {
  const {
    title,
    setTitle,
    shortdesc,
    setShortdesc,
    description,
    setDescription
  } = props

  return (
    <Box sx={{
      flexGrow: 1,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}>

      <Accordion sx={{
        width: "100%",
        marginBottom: "15px",
        p: 2
      }}
        expanded={open} onChange={() => { onOpenChange(!open) }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>{`Description data ${lang.toUpperCase()}`}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TextField id="standard-basic" fullWidth
            label="Title" variant="standard"
            value={title[lang]}
            onChange={e => setTitle({ ...title, [lang]: e.target.value })} />
          <TextField
            id="outlined-textarea"
            label="Short description"
            multiline
            fullWidth
            variant="standard"
            value={shortdesc[lang]}
            onChange={e => setShortdesc({ ...shortdesc, [lang]: e.target.value })}
            rows={4}
          />
          {/* <TextField
            id="outlined-textarea"
            label="Description"
            multiline
            fullWidth
            variant="standard"
            value={description[lang]}
            onChange={e => setDescription({ ...description, [lang]: e.target.value })}
            rows={8}
          /> */}
          {/* <Editor /> */}
          <Box sx={{ mt: 2 }}>
            <div> Description</div>
            <Editor
              apiKey={'lk7jkcbyl4xvkqvh21eexk6k3a6kbw4xfyoczfhq412qsg3a'}
              value={description[lang]}
              init={{
                height: 450,
                menubar: false,
                max_height: 450,
                resize: false
              }}

              onEditorChange={(content, editor) => setDescription({ ...description, [lang]: content })}
            />
          </Box>

        </AccordionDetails>
      </Accordion>
    </Box>
  )
}

const ImageUploadDropzone = (props) => {

  return (
    <Box sx={{
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}>
      <Paper
        elevation={3}
        sx={{
          width: '100%',
          maxWidth: '100%',
          m: 1,
          p: 2
        }}
      >
        <ImageUploadForm  {...props} />
      </Paper>
    </Box>
  )
}

const ImageGallery = ({ images, setImages }) => {
  return (
    <ImageList sx={{}} variant="standard" cols={4} gap={2}>
      {/* <ImageListItem key="Subheader" cols={4}>
              <ListSubheader component="div">Images</ListSubheader>
          </ImageListItem> */}
          
      {images.map((item, index) => (
        <ImageListItem key={index} sx={{ maxHeight: "345px", maxWidth: "345px", overflow: "hidden" }}>
          <img
            src={`${converPath(item.path)}?w=248&fit=crop&auto=format`}
            srcSet={`${converPath(item.path)}?w=248&fit=crop&auto=format&dpr=2 2x`}
            alt={item.name}
            loading="lazy"
          />
          <ImageListItemBar
            position="top"
            // title={item.name}
            actionIcon={
              <IconButton
                sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                aria-label={`info about ${item.title}`}
                onClick={() => {
                  const index = images.indexOf(item)
                  if (index != -1) {
                    images.splice(index, 1)
                    setImages([...images])
                  }
                }}
              >
                <CloseIcon />
              </IconButton>
            }
          />
        </ImageListItem>
      ))}
    </ImageList>
  )
}


// Helper Components
const Row = ({ children, style }) => {
  return (
    <Box sx={{
      display: "flex",
      width: "100%",
      mt: 3,
      mb: 3,
      gap: 2,
      ...style
    }}>
      {children}
    </Box>
  )
}



