import { useContext, useEffect, useState, createContext, useMemo } from "react";
import { useNavigate, Outlet, useLocation, Link } from "react-router-dom";

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';


import API from '../api/public'



import AppBar from './AppBar.js'


const NotFound = () => {


    const [user, setUser] = useState(null)


    useEffect(() => {
        API.user.authorise().then((user) => {
            setUser(user)
        }).catch(error => console.log(error))

    }, [])





    return (<>
        <Container maxWidth="lg">
            <AppBar user={user} />
            <Box sx={{
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "column",
                mt: 2,
                minHeight: "200px",
                justifyContent: "center",
                alignItems: "center"
            }}>
                <div style={{
                    fontSize: 35,
                    display: "flex",
                    textAlign: "center",
                    flexDirection: "column"
                }}> <span style={{
                    fontSize: 40
                }}>Upps!</span> <span>Stranica koju ste tražili više ne postoji. 😓</span>
                </div>

                <Button size="small" variant="outlined" sx={{mt:8}}>
                    <Link style={{textDecoration: "none", color: "inherit"}} to={`/`}> Nazad na naslovnu</Link>
                </Button>

            </Box>
        </Container>
    </>
    );
}


export default NotFound


