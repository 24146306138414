import API from '../api/private'

import React, { useEffect, useState, useContext } from "react";

import { useNavigate, useOutletContext, useLocation } from "react-router-dom";

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import ListView from '../components/ListView'


import { TitleContext } from "../context/TitleContext";

const UserListRoute = (props) => {

    const [userList, setUserList] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    const { setTitle } = useContext(TitleContext);

    useEffect(() => {
        API.user.get().then(userList => {
            if (userList) {
                setUserList(userList)
            }
        }).catch(e => console.log(e));

        setTitle("Users");
    }, [])


    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                {userList.length > 0 ?
                    <ListView list={userList} keys={['name', 'email']}/> :
                    <Box sx={{
                        height: 150,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                        <Typography variant="subtitle1" component="div">
                            List is empty!!!
                        </Typography>
                    </Box>
                }
            </Grid>
            <Grid item xs={12}>
                <Button variant="contained" onClick={() => { navigate("create") }}> Create New User</Button>
            </Grid>
        </Grid>
    )

}


UserListRoute.displayName = "UserListRoute"
export default UserListRoute