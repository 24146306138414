import API from '../api/private'
import React, { useEffect, useState, useContext } from "react";
import { useNavigate, Outlet, useLocation, Link, Navigate } from "react-router-dom";


import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import ListView from '../components/ListView'


import { TitleContext } from "../context/TitleContext";

export default (props) => {

    const TAG = "MAGAZINES.ROUTE"
    const [magazineList, setMagazineList] = useState([]);
    const navigate = useNavigate();

    const { setTitle } = useContext(TitleContext);

    useEffect(() => {
        setTitle("Magazines")
    }, [])

    useEffect(() => {
        API.magazine.get().then(magazineList => {
            if (magazineList) {
                setMagazineList(magazineList)
            }
        }).catch(e => console.log(e));
    }, [])

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                {magazineList.length > 0 ?
                    <ListView list={magazineList.reverse()} keys={['name', 'title']}/> :
                    <Box sx={{
                        height: 150,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                        <Typography variant="subtitle1" component="div">
                            List is empty!!!
                        </Typography>
                    </Box>
                }
            </Grid>
            <Grid item xs={12}>
                <Button variant="contained" onClick={() => { navigate("create") }}> Create New Magazine</Button>
            </Grid>
        </Grid>
    )

}