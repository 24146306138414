import { useContext, useEffect, useState, createContext, useMemo } from "react";
import { useNavigate, Outlet, useLocation } from "react-router-dom";

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ListSubheader from '@mui/material/ListSubheader';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';

import API from '../api/public'



import AppBar from './AppBar.js'
import MagazineItem from './MagazineItem.js'

import { range, rangeRoman, rangeOrdinal } from '../helpers/arrayGenerator.js'
import { converPath } from '../helpers/pathGenerator.js'

const LandingPage = () => {


    const [user, setUser] = useState(null)
    const [magazineList, setMagazineList] = useState([]);

    useEffect(() => {
        API.magazine.getList().then(magazineList => {
            if (magazineList) {
                setMagazineList(magazineList)
            }
        }).catch(e => console.log(e));
    }, [])


    useEffect(() => {
        API.user.authorise().then((user) => {
            setUser(user)
        }).catch(error => console.log(error))

    }, [])





    const groupByYear = useMemo(() => {
        return magazineList.reduce((group, magazine) => {
            const { info } = magazine;
            const { year } = info;
            if(year) {
                group[year.toString()] = group[year] ?? [];
                group[year].push(magazine);
            }
            return group;
        }, {});
    }, [magazineList])

    return (<>
        <Container maxWidth="lg">
            <AppBar user={user} />
            {/* {
                magazineList.map(magazine => <MagazineItem magazine={magazine}/>)
            } */}

            <ImageList sx={{}} variant="standard" cols={4} gap={2}>



                {
                    Object.keys(groupByYear).sort((a, b) => a > b ? -1 : 1).map((year) => {
                        const magazineList = groupByYear[year];
                        const Items = []
                        Items.push(<ImageListItem key="Subheader" cols={4}>
                            <ListSubheader component="div"
                             sx={{
                                 fontSize: "24px",
                                 mt:2,
                                 borderTop: "1px solid #5e2c8b26"
                                //  background: "linear-gradient(90deg, #a2da37 0%, #f5f13b 33%, #b14f09 66%, #c1c4c7 100%);"
                                 }}>{`${year}`}</ListSubheader>
                            </ImageListItem>)

                        magazineList.forEach((magazine, index) => {
                            if (magazine.active == false || magazine.cover == null) {
                                return null
                            }

                            const { cover, title, description } = magazine;
                            Items.push(<ImageListItem key={index} sx= {{
                                ml:2, mr:2,
                                backgroundColor: "#fff",
                                color: "rgba(0, 0, 0, 0.87)",
                                transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                                borderRadius: "4px",
                                boxShadow: "6px 9px 5px -1px rgba(181,174,181,1)",
                                overflow: "hidden",
                                maxWidth: "345px",
                                margin: "8px"
                                
                                }}>
                                <img
                                    src={`${converPath(cover.path)}?w=161&fit=crop&auto=format`}
                                    loading="lazy"
                                />
                                <ImageListItemBar
                                    title={title || "Apartman Plus"}
                                    // subtitle={"Tema broja: Poslovanje i aksnakj sajk sasasasasasa"}
                                    // TODO: Add some popup to see description
                                    actionIcon={
                                        <IconButton
                                            sx={{ color: 'rgba(255, 255, 255, 0.3)' }}
                                        >
                                            <InfoIcon />
                                        </IconButton>
                                    }
                                />
                            </ImageListItem>)
                        })
                        return Items

                    })
                }


            </ImageList>
        </Container>
    </>
    );
}


export default LandingPage


