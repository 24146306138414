export function range(size, startAt = 0) {
    return [...Array(size).keys()].map(i => i + startAt);
}


export function rangeRoman(size, startAt = 0) {
    return [...Array(size).keys()].map(i => i + startAt).map(i => romanize(i));
}


export function rangeOrdinal(size, startAt = 0) {
    return [...Array(size).keys()].map(i => i + startAt).map(i => `${i}.`);
}


function romanize (num) {
    if (isNaN(num))
        return NaN;
    var digits = String(+num).split(""),
        key = ["","C","CC","CCC","CD","D","DC","DCC","DCCC","CM",
               "","X","XX","XXX","XL","L","LX","LXX","LXXX","XC",
               "","I","II","III","IV","V","VI","VII","VIII","IX"],
        roman = "",
        i = 3;
    while (i--)
        roman = (key[+digits.pop() + (i * 10)] || "") + roman;
    return Array(+digits.join("") + 1).join("M") + roman;
}