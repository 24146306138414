import { useContext, useEffect, useState, createContext } from "react";
import { useNavigate, Outlet, useLocation } from "react-router-dom";


import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';


import { TitleContext } from "../context/TitleContext";

const Home = () => {

    const { setTitle } = useContext(TitleContext);

    setTitle("Home");

    return (
        <Grid container spacing={3}>
            <Grid item xs={6}>
                <Paper
                    sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        // height: 120,
                    }}
                >
                </Paper>
            </Grid>

            <Grid item xs={6}>
                <Paper
                    sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        // height: 120,
                    }}
                >
                </Paper>
            </Grid>
        </Grid>
    )
}

Home.displayName = "HomeRoute";
export default Home