import {createContext, useState} from "react";

export const AdminContext = createContext();

export function AdminContextProvider({ children }) {
  const [admin, setAdmin] = useState("");

  const defaultContext = {
    admin,
    setAdmin,
  };
  
  return (
    <AdminContext.Provider value={defaultContext}>
      {children}
    </AdminContext.Provider>
  );
}

